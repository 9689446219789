.permissions-for-company{
  max-height: 200px;
  overflow-y: auto;
  border: 1px dashed;
  margin: 10px 0px 50px 0px;
  padding: 15px;

  .informational-grid {
    margin: 0 auto;
    border: 1px solid;

    td {
      padding: 0px 5px;
      border: 1px solid;

      svg {
        height: 20px;
      }
    }
  }
}